import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HandlerError {
  constructor(private _toastr: ToastrService) {}

  handle(error: HttpErrorResponse): Observable<any> {
    if (error.status >= 500) {
      this._toastr.error(`${error.message}`, 'SERVER ERROR');
    } else if (error.status === 404) {
      this._toastr.warning('Resource not found');
    } else if (error.status === 401) {
      this._toastr.warning('Signature has expired');
      localStorage.clear();
      window.location.reload();
    } else if (error.status === 403) {
    } else if (error.status === 0) {
      this._toastr.warning('Request timeout');
    } else if (error.status === 402) {
      return throwError(error);
    } else if (error.status === 400) {
      if (error.error.message) {
        const msg =
          error.error.message.charAt(0).toUpperCase() +
          error.error.message.slice(1).replace(/ *\([^)]*\) */g, '');
        this._toastr.error(msg);
      }
    } else {
      this._toastr.warning(error.status + error.message, 'UNKNOW ERROR');
    }
    return throwError(error);
  }
}
