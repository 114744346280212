import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AuthGuard } from './common/services/auth/auth.guard';
import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { BaseService } from './common/services/base.service';
import { HandlerError } from './common/shared/error-handler';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { TokenInterceptor } from './common/shared/token.interceptor';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxProgressiveImageLoaderModule, IImageLoaderOptions } from 'ngx-progressive-image-loader';
import  {  NgxProgressiveImgLoaderModule  }  from  'ngx-progressive-img-loader';
import { NotFoundComponent } from './modules/not-found/not-found.component';
const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./modules/private/private.module').then(mod => mod.PrivateModule),
    data: { preload: true,},
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule),
    data: {
      title: 'BEULAH'
    }
  },
  {
    path: '**',
    loadChildren: () => import('./modules/not-found/not-found.module').then(mod => mod.NotFoundModule),
    data: {
      title: 'BEULAH'
    }
  },
];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    DragDropModule,
    HttpClientModule,
    CommonModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
        tapToDismiss: true,
        onActivateTick: true
      }),
    NgxProgressiveImageLoaderModule.forRoot(),
    NgxProgressiveImgLoaderModule
  ],
  providers: [
    BaseService,
    HandlerError,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
