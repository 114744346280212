import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseService } from '../base.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string;
  constructor(
    private _base: BaseService
  ) { 
    if (this.token) {
      this.isLoggedIn = true;
    }
  }
  get token() {
    return localStorage.getItem(environment.TOKEN) || null;
  }

  set token(token) {
    localStorage.setItem(environment.TOKEN, token);
  }

  logout() {
    this.isLoggedIn = false;
    localStorage.clear();
    window.location.reload();
  }

  login(body): Observable<any> {
    const url = `${environment.apiURL}/api/v1/partner/auth/login`;
    return this._base.post(url, body, false);
  }

  refreshToken(body): Observable<any> {
    const url = `${environment.apiURL}/api/v1/partner/auth/refresh-token`;
    return this._base.post(url, body, false);
  }
}
