import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

   constructor() { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem(environment.TOKEN);
      if (token) {
         request = this.addToken(request, token);
      }
      return next.handle(request);
   }

   private addToken(request: HttpRequest<any>, token: string) {
      return request.clone({
         setHeaders: {
            'Authorization': `${token}`
         }
      });
   }

}
