import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { catchError, map } from 'rxjs/operators';
import { HandlerError } from '../shared/error-handler';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  headers: HttpHeaders;
  headersForm: HttpHeaders

  constructor(
    private _http: HttpClient,
    private _handler: HandlerError
  ) {
    this.headers = this.setHeaders();
    this.headersForm = this.setHeadersForm();
  }

  setHeaders(): HttpHeaders {
    const header = new HttpHeaders();
    const token = localStorage.getItem(environment.TOKEN);
    if (!token) {
      return header.set('Content-type', 'application/json')
    }
    return header.set('Content-type', 'application/json').set('Authorization', `${token}`);
  }

  setHeadersForm(): HttpHeaders {
    const header = new HttpHeaders();
    const token = localStorage.getItem(environment.TOKEN);
    return header.set('Authorization', `${token}`);
  }

  get(url: string) {
    return this._http.get(url, { headers: this.headers })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    )
  }

  post(url: string, body: Object, isForm?: boolean) {
    return this._http.post(url, body, { headers: isForm ? this.headersForm: this.headers })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    )
  }

  put(url: string, body: Object, isForm?: boolean) {
    return this._http.put(url, body, { headers: isForm ? this.headersForm: this.headers })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    )
  }

  delete(url: string) {
    return this._http.delete(url, { headers: this.headers })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    )
  }
}
